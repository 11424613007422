<style>
</style>
<style scoped="">
	.component_box {width: 100%;height: 100%;display: flex;flex-direction: column;}
	.component_box-body {flex: 1;}
	.component_box-header {display: flex;align-items: center;padding-bottom: 5px;}
	.component_box-header--title {flex: 1;font-weight: bold;}
	.component_box-header--close img {width: 30px;cursor: pointer;}
	.component_box-body--container {width: 100%;height: 100%;}
	.table_box {height: 100%;width: 100%;display: flex;flex-direction: column;}
	.table_box-container {flex: 1;}
	.table_box-container--content {width: 100%;height: 100%;}
	.component_box-footer {text-align: right;padding: 5px;}
	.info_pop_item{display: flex;padding: 5px 0;}
	.info_pop_item-lable{padding-right: 10px;color: #BCBEC2;}
</style>
<template>
	<div id="p_202002241313" class="component_box">
		<div class="component_box-header">
			<div class="component_box-header--title">缴费任务详情
				<span style="margin-left: 30px;">应交合计: {{yjhj}} 元</span>
				<span style="margin-left: 30px;" v-for="item in items_total_money">{{item.item_name}}: {{item.money}} 元</span>
			</div>
			<div class="component_box-header--close">
				<img @click="closeThisPage()" src="../../assets/icon/cha.png" alt="">
			</div>
		</div>
		<div class="component_box-body">
			<div class="component_box-body--container">
				<div class="table_box">
					<div class="table_box-function">
						<!-- <div class="table_box-function-item">
							<el-cascader placeholder="试试搜索：指南" :options="options" filterable></el-cascader>
						</div> -->
					</div>
					<div class="table_box-container">
						<div class="table_box-container--content">
							<el-table :data="tableData" :height="tableH" border style="width: 100%">
								<el-table-column prop="room.code" label="房屋编号" width="180"></el-table-column>
								<el-table-column prop="name" label="房屋位置" width="180">
									<template slot-scope="scope">
										{{scope.row.residence_name}}-{{scope.row.build_name}}-{{scope.row.unit_name}}
									</template>
								</el-table-column>
								<el-table-column prop="room.jz_area" label="建筑面积/㎡"></el-table-column>
								<el-table-column prop="pay_status_text" label="缴费情况"></el-table-column>
								<el-table-column prop="charge_month" label="缴费月份"></el-table-column>
								<el-table-column prop="item_name" label="缴费项目"></el-table-column>
								<el-table-column prop="ys_fy" label="应收金额(元)"></el-table-column>
								<el-table-column prop="release_time" label="发布时间" width="160"></el-table-column>
								<el-table-column prop="item_name" label="" width="100">
									<template slot-scope="scope">
										<el-popover placement="right" width="400" trigger="hover">
											<div class="info_pop">
												<div class="info_pop_item">
													<div class="info_pop_item-lable">计算公式 </div>
													<div class="info_pop_item-value">{{scope.row.formula_name}}</div>
												</div>
												<div class="info_pop_item">
													<div class="info_pop_item-lable">计算时间 </div>
													<div class="info_pop_item-value">{{scope.row.js_sj}}</div>
												</div>
												<div class="info_pop_item">
													<div class="info_pop_item-lable">单价 </div>
													<div class="info_pop_item-value">{{scope.row.dj}} 元</div>
												</div>
											</div>
											<el-button type="text" slot="reference">查看详情</el-button>
										</el-popover>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="component_box-footer">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
			 :page-sizes="[20]" :page-size="20" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				tableH: 0,
				pageNumber: 1,
				pageTotal: 0,
				tableData: [],
				yjhj:0,
				items_total_money:[]
			}
		},
		created: function() {
			_this = this;
			_this.apiGetTableData();
			console.log(_this.pageParams)
		},
		mounted: function() {
			_this.$nextTick(function() {
				var tabH = document.getElementsByClassName("table_box-container--content")[0].offsetHeight;
				_this.tableH = tabH;
			})

		},
		methods: {
			/** ->  分页
			Author:郑凯 
			create_time:2021-3-1 14:39:10 */
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.apiGetTableData();
			},

			/** ->  获取表格数据
			Author:郑凯 
			create_time:2021-3-1 14:39:19 */
			apiGetTableData() {
				_this._postApi('/wy/cost/taskDetail', {
					page: _this.pageNumber,
					limit: 20,
					tid: _this.pageParams.id
				}).then(function(res) {
					_this.pageTotal = res.data.count;
					_this.tableData = res.data.list;
					_this.yjhj = res.data.total_money;
					_this.items_total_money = res.data.items_total_money;
				}).catch(function(err) {

				})
			},

			/** ->  关闭
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-25 13:40:16 */
			closeThisPage() {
				_this.$emit('childrenEvent', {
					type: 'close'
				})
			}
		}
	}
</script>
