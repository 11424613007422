<style scoped>
	.tpm_box{width: 100%;height: 100%;display: flex;flex-direction: column;position: relative;}
	.tem_body{flex: 1;width: 100%;}
	.tem_header{padding: 5px;display: flex;align-items: center;};
	.tem_body_contair{height: 100%;width: 100%;}
	.tem_footer{text-align: right;padding: 5px;}
	.mb{padding: 0 20px 0 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
	
	/* 计算 */
	.js_box{background-color: #F1F1F1;}
	.jsfw{padding: 10px;}
	.btn_sc{width: 150px;cursor: pointer;background-color: #19BE6B;border: none;padding: 0 0;color: #FFF;}
	.btn_sc::active{border: none;outline: none;}
	.btn_sc:focus{border: none;outline: none;}
	
	.table--btn{margin: 0 10px;cursor: pointer;white-space: nowrap;display: inline-block;}
	.table--btn.delete{color: red;}
	.table--btn.edit{color: blue;}
	
</style>
<style>
	table{box-sizing: border-box;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	#p_202002241304 .el-table__body tr.current-row>td{background-color: #FFF !important;color: #333;}
	#p_202002241304 .el-form-item{padding-bottom: 0;margin-bottom: 0;}
	.mb .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.1rem;}
	.el-table__expanded-cell[class*=cell]{padding: 5px;background-color: #FFF;padding-bottom: 20px;}
	.el-table .el-table__expanded-cell:hover{background-color: rgb(255,255,255)!important;}
	.el-table .tableRowClassName{background: #FFF;}
	.house_name{font-weight: bold;font-size: 1.2rem;}
	
	.qt_yable{background-color: #F5F5F5;}
	.qt_yable td,.qt_yable th{padding: 5px 10px;text-align: center;}
	
	#p_202002241304 .el-dialog.my_dialog .el-dialog__header{padding: 0!important;}
</style>
<template>
	<div class="tpm_box" id="p_202002241304">
		<div class="tem_header">
			<div class="mb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item class="">{{buildPageParams.community.name}}</el-breadcrumb-item>
					<el-breadcrumb-item class="" v-if="buildPageParams.treeLv>0">{{buildPageParams.build.name}}</el-breadcrumb-item>
					<el-breadcrumb-item class="" v-if="buildPageParams.treeLv>1">{{buildPageParams.unit.name}}</el-breadcrumb-item>
					<el-breadcrumb-item class="" v-if="buildPageParams.treeLv>2">{{buildPageParams.room.code}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- <el-button type="warning" size="small" @click="bindBtn">费用生成<i class="el-icon-circle-plus-outline"></i></el-button> -->
			<!-- <el-select v-model="selectDate" placeholder="请选择" size="small" style="margin: 0 20px;" @change="dateSelect">
				<el-option v-for="item in dateoptions" :key="item" :label="item" :value="item"></el-option>
			</el-select> -->
			<el-button type="danger" size="small" @click="thisReload">刷新<i class="el-icon-refresh-right"></i></el-button>
		</div>
		<div class="tem_body" id="tem_body">
			<div class="tem_body_contair" id="table_box" v-loading="tableLoading" v-if="!tableLoading">
				<el-table :data="tableData" :height="tableHeight" border highlight-current-row :default-expand-all="true">

					<el-table-column prop="js_tj.info" label="计算范围" width=""></el-table-column>
					<el-table-column prop="js_tj.start_month" label="起始月份" width=""></el-table-column>
					<el-table-column prop="js_tj.end_month" label="截止月份" width=""></el-table-column>
					<el-table-column prop="js_tj.items" label="计算项目" width="300"></el-table-column>
					<!-- <el-table-column prop="js_tj.no_pay_op_text" label="未缴费处理方式" width=""></el-table-column> -->
					<el-table-column prop="js_tj.start_op_text" label="启费日期是否参与计算" width=""></el-table-column>
					<el-table-column prop="status_text" label="计算状态" width=""></el-table-column>
					<el-table-column prop="release_status_text" label="发布状态" width="160">
						<template slot-scope="scope">
							<div>{{scope.row.release_status_text}}</div>
							<div>{{scope.row.release_time}}</div>
						</template>
					</el-table-column>
					<el-table-column fixed="right" prop="remark" label="" width="200">
						<template slot-scope="scope">
							<el-row>
								<el-button size="mini" type="text" @click="seeInfo(scope.row)" style="color: orange;">查看详情</el-button>
								<el-button size="mini" type="text" @click="deleteRe(scope.row)" style="color: red;">删除</el-button>
								<el-button size="mini" type="primary" v-if="scope.row.status == 2 && scope.row.release_status == 1" :loading="elButtnLoad && clickRowId == scope.row.id"
								 @click="apiRelease(scope.row)">发布</el-button>
								<el-button size="mini" type="danger" v-if="scope.row.status == 2 && scope.row.release_status == 2" :loading="elButtnLoad && clickRowId == scope.row.id"
								 @click="releaseCancel(scope.row)">撤回</el-button>
							</el-row>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="tem_footer">
			<el-pagination @current-change="handleCurrentChange" :current-page="pageNumber" :page-sizes="[20]" :page-size="pageSize"
			 layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination>
			<div class="js_box">
				<div class="jsfw">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item class="">计算范围</el-breadcrumb-item>
						<el-breadcrumb-item class="">{{buildPageParams.community.name}}</el-breadcrumb-item>
						<el-breadcrumb-item class="" v-if="buildPageParams.treeLv>0">{{buildPageParams.build.name}}</el-breadcrumb-item>
						<el-breadcrumb-item class="" v-if="buildPageParams.treeLv>1">{{buildPageParams.unit.name}}</el-breadcrumb-item>
						<el-breadcrumb-item class="" v-if="buildPageParams.treeLv>2">{{buildPageParams.room.code}}</el-breadcrumb-item>
						<el-breadcrumb-item class="">
							<span style="color: red;">起始时间至截止时间不能大于12个月;计算项目多选,如不选则计算全部项目</span>
						</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<!-- 计算参数 -->
				<el-form label-width="auto" @submit.native.prevent>
					<el-row :gutter="0" style="display: flex;width: 98%;margin:auto;">
						<el-form-item label="起始月份" style="padding:0 5px;">
							<el-date-picker format="yyyy-MM" value-format="yyyy-MM" v-model="generaParams.start_month" type="month"
							 placeholder="选择日期" size="mini"></el-date-picker>
						</el-form-item>
						<el-form-item label="截止月份" style="padding:0 5px;">
							<el-date-picker format="yyyy-MM" value-format="yyyy-MM" v-model="generaParams.end_month" type="month"
							 placeholder="选择日期" size="mini"></el-date-picker>
						</el-form-item>
						<el-form-item label="计算项目" style="padding:0 5px;">
							<el-select size="mini" v-model="generaParams.items" multiple collapse-tags style="margin-left: 20px;"
							 placeholder="请选择">
								<el-option v-for="item in projectOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="启费日期是否参与计算" label-width="160px" style="padding:0 5px 0 20px;">
							<el-select size="mini" v-model="generaParams.start_op" placeholder="启费日期是否参与计算">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<el-row :gutter="0" style="display: flex;">
						<!-- <el-form-item label="未缴费处理">
							<el-select size="mini" v-model="generaParams.no_pay_op" placeholder="启费日期是否参与计算">
								<el-option v-for="item in noPayOpOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item label-width="auto">
							<el-button size="small" type="success" @click="jisuanStar()">开始计算</el-button>
						</el-form-item>
					</el-row>
				</el-form>
			</div>
		</div>

		<el-dialog custom-class="my_dialog" width="40%" top="none" title="" :visible.sync="bindStandardDialog"
		 destroy-on-close>
			<bind_standard v-if="bindStandardDialog" :pageParams="dialogPageParams" v-on:childrenEvent="bindStandardEvent"></bind_standard>
		</el-dialog>

		<el-dialog custom-class="my_dialog my_" width="30%" top="none" title="" :visible.sync="infoDialog" destroy-on-close
		 fullscreen :close-on-click-modal="false" :show-close="false">
			<info v-if="infoDialog" :pageParams="infopageParams" v-on:childrenEvent="infoEvent"></info>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Loading
	} from 'element-ui';
	import bind_standard from './bind_standard.vue';
	import info from './info.vue';
	import {
		start_op,
		no_pay_op
	} from '../../assets/js/config.js' //注意路径
	var _this;
	export default {
		components: {
			bind_standard,
			info
		},
		props: ['buildPageParams'],
		data() {
			return {
				communityId: 0,
				pageParams: null,
				tableLoading: true,
				tableHeight: 0,
				tableData: [],
				pageNumber: 1,
				pageSize: 20,
				pageTotal: 0,
				selectTableRow: null, // 选中的表格某行
				bindStandardDialog: false,
				dialogPageParams: {},
				bindPageTitle: '',
				dateoptions: [], //日期选择
				selectDate: '',
				generaParams: {
					start_month: '',
					end_month: '',
					no_pay_op: '',
					start_op: '',
					items: []
				},
				options: [],
				noPayOpOptions: [],
				projectOptions: [],
				loading: true,
				infoDialog: false,
				infopageParams: null,
				elButtnLoad: false,
				clickRowId: 0
			}
		},
		created: function() {
			_this = this;

			_this.communityId = _this.buildPageParams.community.id;
			_this.pageParams = _this.buildPageParams;
			_this.options = start_op;
			console.log(_this.pageParams)
			_this.apiGetProjectOptions();
			_this.noPayOpOptions = no_pay_op;
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				// _this.apiGetTimeDate();
				_this.api_getTableData();

			})
		},
		methods: {
			/** --------> 方法介绍: 刷新
			author:郑凯 创建时间:2021-1-25 13:43:30 */
			thisReload() {
				_this.selectTableRow = null;
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				// _this.apiGetTimeDate();
				_this.api_getTableData();
				_this.apiGetProjectOptions();
			},

			/** ->  获取项目
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-24 17:00:55 */
			apiGetProjectOptions() {
				var aData = _this.pageParams;
				var pparam = {};
				if (aData.community) {
					pparam.residence_id = aData.community.id;
				}
				if (aData.build) {
					pparam.build_id = aData.build.id;
				}
				if (aData.unit) {
					pparam.unit_id = aData.unit.id;
				}
				if (aData.room) {
					pparam.room_id = aData.room.id;
				}
				_this._postApi('/wy/cost/getChargeItemByObj', pparam).then(function(res) {
					_this.projectOptions = res.data;
				}).catch(function(err) {

				})
			},

			/** --------> 方法介绍: 切换分页
			author:郑凯 创建时间:2021-1-25 13:43:40 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_getTableData();
			},

			/** --------> 方法介绍: 获取日期
			author:郑凯 创建时间:2021-2-3 16:24:47 */
			apiGetTimeDate() {
				_this._postApi('/wy/cost/months', {
					residence_id: _this.communityId
				}).then(function(res) {
					_this.dateoptions = res.data;
					_this.selectDate = _this.dateoptions[0];
					_this.api_getTableData();
				}).catch(function(erro) {

				})
			},

			dateSelect(e) {
				console.log(e)
				_this.selectDate = e;
				_this.api_getTableData();
			},

			/** --------> 方法介绍: 获取表格数据
			author:郑凯 创建时间:2021-1-25 13:43:53 */
			api_getTableData() {
				_this.tableLoading = true;
				_this._postApi('/wy/cost/taskList', {
					residence_id: _this.communityId,
					charge_month: _this.selectDate,
					page: _this.pageNumber,
					limit: _this.pageSize
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data.list;
						_this.pageTotal = res.data.count;
						baseData.forEach(function(item) {

						})
						_this.tableData = baseData;
						_this.tableLoading = false;
					} else {

					}
				}).catch((err) => {});
			},

			// _ *** 上传弹框事件消息
			bindStandardEvent(e) {
				switch (e.type) {
					case 'close':
						_this.bindStandardDialog = false;
						break;
					case 'success':
						_this.thisReload();
						_this.$emit('childrenEvent', {
							type: 'add'
						});
						_this.bindStandardDialog = false;
						break;
				}
			},

			/** ->  开始计算
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-24 17:28:36 */
			jisuanStar() {
				let loadingInstance = Loading.service({
					lock: true,
					text: '提交中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.2)'
				});
				var apiParams = _this.generaParams;
				var aData = _this.pageParams;
				if (aData.community) {
					apiParams.residence_id = aData.community.id;
				}
				if (aData.build) {
					apiParams.build_id = aData.build.id;
				}
				if (aData.unit) {
					apiParams.unit_id = aData.unit.id;
				}
				if (aData.room) {
					apiParams.room_id = aData.room.id;
				}
				_this._postApi('/wy/cost/create', apiParams).then((res) => {
					if (res.code == 1) {
						_this.api_getTableData();
						loadingInstance.close();
					} else {
						_this.$alert(res.msg, '提示', {
							confirmButtonText: '确定',
							callback: action => {
								loadingInstance.close();
							}
						});
					}
				}).catch((err) => {});
			},

			/** ->  发布
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-25 13:42:23 */
			apiRelease(data) {
				const h = _this.$createElement;
				_this.$msgbox({
					title: '提示',
					message: h('p', null, [
						h('span', null, '此操作将计算缴费发布内容，用户将看到收费信息, 是否继续? '),
						// h('span', {
						// 	style: 'color: teal'
						// }, 'VNode')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '发布中...';
							_this.clickRowId = data.id;
							_this.elButtnLoad = true;
							_this._postApi('/wy/cost/release', {
								tid: data.id
							}).then((res) => {
								if (res.code == 1) {
									setTimeout(function(){
										done();
										_this.api_getTableData();
										_this.elButtnLoad = false;
										instance.confirmButtonLoading = false;
									},2000)
									
								} else {
									_this.$alert(res.msg, '提示', {
										confirmButtonText: '确定',
										callback: action => {
											_this.elButtnLoad = false;
										}
									});
								}
							}).catch((err) => {});
						} else {
							done();
						}
					}
				}).then(action => {
					
				});
			},

			/** ->  撤回
			Author:郑凯 
			create_time:2021-3-1 15:21:55 */
			releaseCancel(data) {
				_this.$confirm('此操作将撤回发布内容，未缴费用户将看不到收费信息, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.clickRowId = data.id;
					_this.elButtnLoad = true;
					_this._postApi('/wy/cost/releaseCancel', {
						tid: data.id
					}).then((res) => {
						if (res.code == 1) {
							_this.api_getTableData();
							_this.elButtnLoad = false;
						} else {
							_this.$alert(res.msg, '提示', {
								confirmButtonText: '确定',
								callback: action => {
									_this.elButtnLoad = false;
								}
							});
						}
					}).catch((err) => {});
				}).catch(() => {

				});

			},
			
			/** ->  删除
			Author:郑凯 
			create_time:2021-3-2 15:56:02 */
			deleteRe(data) {
				_this.$confirm('此操作将删除该条发布内容, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.clickRowId = data.id;
					_this.elButtnLoad = true;
					_this._postApi('/wy/cost/delete', {
						tid: data.id
					}).then((res) => {
						if (res.code == 1) {
							_this.api_getTableData();
							_this.elButtnLoad = false;
						} else {
							_this.$alert(res.msg, '提示', {
								confirmButtonText: '确定',
								callback: action => {
									_this.elButtnLoad = false;
								}
							});
						}
					}).catch((err) => {});
				}).catch(() => {
			
				});
			
			},

			/** ->  查看详情
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-25 13:10:12 */
			seeInfo(data) {
				_this.infopageParams = data;
				_this.infoDialog = true;
			},

			/** ->  查看详情 组件通信
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-25 13:08:22 */
			infoEvent(e) {
				switch (e.type) {
					case 'close':
						_this.infoDialog = false;
						break;
				}
			},
		}
	}
</script>
