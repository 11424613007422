<style scoped>
	.dialog_page_footer{text-align: right;}
	.community_box{max-height: 12rem;overflow-y: auto;}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="100px">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="">
							<el-breadcrumb separator-class="el-icon-arrow-right" v-if="pageParams.upPage == 'community'">
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.community.name}}</el-breadcrumb-item>
							</el-breadcrumb>
							<el-breadcrumb separator-class="el-icon-arrow-right" v-if="pageParams.upPage == 'build'">
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.community.name}}</el-breadcrumb-item>
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.build.name}}</el-breadcrumb-item>
							</el-breadcrumb>
							<el-breadcrumb separator-class="el-icon-arrow-right" v-if="pageParams.upPage == 'unit'">
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.community.name}}</el-breadcrumb-item>
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.build.name}}</el-breadcrumb-item>
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.unit.name}}</el-breadcrumb-item>
							</el-breadcrumb>
							<el-breadcrumb separator-class="el-icon-arrow-right" v-if="pageParams.upPage == 'room'">
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.community.name}}</el-breadcrumb-item>
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.build.name}}</el-breadcrumb-item>
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.unit.name}}</el-breadcrumb-item>
								<el-breadcrumb-item class="dialog_data_str">{{pageParams.housingEstate.room.code}}</el-breadcrumb-item>
							</el-breadcrumb>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="未缴费处理" label-width="200px">
							<el-select v-model="formData.standard_id" placeholder="未缴费处理">
								<!-- <el-option key="0" label="选择收费标准" value="0"></el-option> -->
								<el-option v-for="item in bzOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="启费日期是否参与计算" label-width="200px">
							<el-select v-model="formData.standard_id" placeholder="启费日期是否参与计算">
								<!-- <el-option key="0" label="选择收费标准" value="0"></el-option> -->
								<el-option v-for="item in bzOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">

					<el-col :span="24">
						<el-form-item label="起始月份" label-width="200px">
							<el-date-picker format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="formData.qf_rq" type="date" placeholder="选择日期"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="截止月份" label-width="200px">
							<el-select v-model="formData.sq_zq" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	import {
		sq_zq
	} from '../../assets/js/config.js' //注意路径
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				btnSaveLoading: false,
				formData: {
					qf_rq: '',
					jf_zq:1,
					sq_zq:'',
					standard_id: ''
				},
				options: [],
				bzOptions:[]
			}
		},
		created: function() {
			_this = this;
			_this.apiGetBzList();
			_this.options = sq_zq;
		},
		mounted: function() {

		},
		methods: {
			/** --------> 方法介绍: 获取收费标准列表
			author:郑凯 创建时间:2021-2-3 12:57:16 */
			apiGetBzList(){
				_this._getApi('/wy/chargeStandard/getList', {
					residence_id: _this.pageParams.housingEstate.community.id,
					page: 1,
					limit: 500
				}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data.list;
						console.log(baseData)
						_this.bzOptions = baseData;
					} else {
				
					}
				}).catch((err) => {});
			},
			
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				var params = _this.formData;
				if(_this.pageParams.upPage == 'community'){
					params.residence_id = _this.pageParams.housingEstate.community.id;
				}else if(_this.pageParams.upPage == 'build'){
					params.build_id = _this.pageParams.housingEstate.build.id;
				}else if(_this.pageParams.upPage == 'unit'){
					params.build_id = _this.pageParams.housingEstate.build.id;
					params.unit_id = _this.pageParams.housingEstate.unit.id;
				}
				
				_this._postApi('/wy/chargeStandardSet/standardBind', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', {
							type: 'success'
						});
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>
